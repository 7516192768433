<template>
  <div v-if="visible" class="right-box">
    <loading :loading="loading" :small="true" />
    <div class="right-content">
      <div class="close-btn" @click="handleClose" />
      <div class="r-top1-box">
        <div class="r-title">
          楼宇信息 <img src="@/assets/image/oneMap/title-right.png">
        </div>
        <div class="r-top1-content">
          <div class="r-top1-row">
            <div class="r-top1-span1">
              <span class="r-top1-row-title">楼宇名称: </span>
              <span class="r-top1-row-text">{{ lou_number }}</span>
            </div>
          </div>
          <div class="r-top1-row">
            <div class="r-top1-span1">
              <span class="r-top1-row-title">楼宇网格名称: </span>
              <span class="r-top1-row-text">{{ wangge_number }}</span>
            </div>

          </div>
          <div class="r-top1-row">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">单元总数: </span>
              <span class="r-top1-row-text">{{ resData.danyuan_num }} 个</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">常住人口: </span>
              <span class="r-top1-row-text">{{ resData.ren_in }} 人</span>
            </div>
          </div>
          <div class="r-top1-row">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">人户都不在: </span>
              <span class="r-top1-row-text">{{ resData.ren_out_hu_out }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">户在人不在: </span>
              <span class="r-top1-row-text">{{ resData.ren_in_hu_out }} 人</span>
            </div>
          </div>
          <div class="r-top1-row">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">未成年人: </span>
              <span class="r-top1-row-text">{{ resData.nonage }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">租&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户: </span>
              <span class="r-top1-row-text">{{ resData.zu }} 人</span>
            </div>
          </div>
          <div class="r-top1-row">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">空&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;房: </span>
              <span class="r-top1-row-text">{{ resData.kongchao }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">中&nbsp;&nbsp;心&nbsp;&nbsp;户: </span>
              <span class="r-top1-row-text">{{ resData.sick }} 人</span>
            </div>
          </div>

          <div class="r-top1-row">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">重&nbsp;&nbsp;点&nbsp;&nbsp;人: </span>
              <span class="r-top1-row-text">{{ resData.imp }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">残&nbsp;&nbsp;疾&nbsp;&nbsp;人: </span>
              <span class="r-top1-row-text">{{ resData.canji }} 人</span>
            </div>
          </div>
          <div class="r-top1-row r-top1-row-last">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">独&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;居: </span>
              <span class="r-top1-row-text">{{ resData.duju }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">空&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;巢: </span>
              <span class="r-top1-row-text">{{ resData.kongchao }} 人</span>
            </div>
          </div>
        </div>
      </div>
      <div class="r-top2-box">
        <div class="r-title">
          单元信息 <img src="@/assets/image/oneMap/title-right.png">
        </div>
        <div class="r-top2-tab">
          <select v-model="selectDanyuanNumber" class="my-select" @change="handleChangeDanyuan">
            <option v-for="(item, index) in resData.danyuan_array" :key="index">
              {{ item.number }}
            </option>
          </select>
        </div>
        <div class="r-top2-content">
          <div class="r-top1-row">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">重&nbsp;&nbsp;点&nbsp;&nbsp;人: </span>
              <span class="r-top1-row-text">{{ danyuan.imp }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">残&nbsp;&nbsp;疾&nbsp;&nbsp;人: </span>
              <span class="r-top1-row-text">{{ danyuan.canji }} 人</span>
            </div>
          </div>
          <div class="r-top1-row">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">中&nbsp;&nbsp;心&nbsp;&nbsp;户: </span>
              <span class="r-top1-row-text">{{ danyuan.sick }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">独&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;居: </span>
              <span class="r-top1-row-text">{{ danyuan.duju }} 人</span>
            </div>
          </div>
          <div class="r-top1-row r-top1-row-last">
            <div class="r-top1-span2">
              <span class="r-top1-row-title">空&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;巢: </span>
              <span class="r-top1-row-text">{{ danyuan.kongchao }} 人</span>
            </div>
            <div class="r-top1-span2">
              <span class="r-top1-row-title">空&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;房: </span>
              <span class="r-top1-row-text">{{ danyuan.no_people }} 人</span>
            </div>
          </div>
        </div>
      </div>
      <div class="r-top3-box">
        <div class="rt3-left-btn" @click="handleRoomLeft" />
        <div class="rt3-right-btn" @click="handleRoomRight" />
        <div class="r-title">
          房间号列表 <img src="@/assets/image/oneMap/title-right.png">
        </div>
        <div class="r-top3-tab">
          <div class="r-t3-t-item r-t3-t-item1">
            房间号
          </div>
          <div class="r-t3-t-item r-t3-t-item1">
            重点人
          </div>
          <div class="r-t3-t-item r-t3-t-item1">
            残疾人
          </div>
          <div class="r-t3-t-item r-t3-t-item1">
            中心户
          </div>
          <div class="r-t3-t-item r-t3-t-item2">
            独居
          </div>
          <div class="r-t3-t-item r-t3-t-item2">
            空巢
          </div>
          <div class="r-t3-t-item r-t3-t-item2">
            空房
          </div>
        </div>
        <div class="r-top3-content">
          <div v-for="(item,index) in showHuList" :key="index" class="r-top3-row">
            <div class="r-t3-t-item r-t3-t-item1">
              {{ item.number }}
            </div>
            <div class="r-t3-t-item r-t3-t-item1">
              <img v-if="item.imp > 0" src="@/assets/image/oneMap/icon-zdr.png">
              <img v-else src="@/assets/image/oneMap/icon-zdr-n.png">
            </div>
            <div class="r-t3-t-item r-t3-t-item1">
              <img v-if="item.canji > 0" src="@/assets/image/oneMap/icon-cjr.png">
              <img v-else src="@/assets/image/oneMap/icon-cjr-n.png">
            </div>
            <div class="r-t3-t-item r-t3-t-item2">
              <img v-if="item.sick > 0" src="@/assets/image/oneMap/icon-dy.png">
              <img v-else src="@/assets/image/oneMap/icon-dy-n.png">
            </div>
            <div class="r-t3-t-item r-t3-t-item2">
              <img v-if="item.duju > 0" src="@/assets/image/oneMap/icon-dj.png">
              <img v-else src="@/assets/image/oneMap/icon-dj-n.png">
            </div>
            <div class="r-t3-t-item r-t3-t-item2">
              <img v-if="item.kongchao > 0" src="@/assets/image/oneMap/icon-kc.png">
              <img v-else src="@/assets/image/oneMap/icon-kc-n.png">
            </div>
            <div class="r-t3-t-item r-t3-t-item2">
              <img v-if="item.no_people > 0" src="@/assets/image/oneMap/icon-kf.png">
              <img v-else src="@/assets/image/oneMap/icon-kf-n.png">
            </div>
          </div>
        </div>

        <div class="r-t3-bottom-box">
          <div v-for="(item,index) in huPage.page" :key="index" :class="{'r-t3-bottom-item': true, 'r-t3-bottom-item-s': index === huPage.current}" @click="handleSelectDanyuanIndex(index)" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import shequApi from '@/api/shequApi'
import Loading from '@/components/Loading.vue'
export default {
  components: { Loading },
  data() {
    return {
      visible: false,
      resData: {},
      lou_number: '',
      wangge_number: '',
      huList: [],
      showHuList: [],
      danyuanArray: [],
      danyuan: {
        canji: 0,
        duju: 0,
        imp: 0,
        kongchao: 0,
        no_people: 0,
        sick: 0
      },
      huPage: {
        current: 0,
        page: 0
      },
      loading: false,
      selectDanyuanNumber: ''
    }
  },
  methods: {
    handleClose() {
      this.visible = false
      this.$emit('on-close')
    },
    handleRoomLeft() {
      if (this.huPage.current === 0) {
        this.handleSelectDanyuanIndex(this.huPage.page - 1)
      } else {
        this.handleSelectDanyuanIndex(this.huPage.current - 1)
      }
    },
    handleRoomRight() {
      if (this.huPage.current === this.huPage.page - 1) {
        this.handleSelectDanyuanIndex(0)
      } else {
        this.handleSelectDanyuanIndex(this.huPage.current + 1)
      }
    },
    handleSelectDanyuanIndex(index) {
      this.huPage.current = index
      this.showHuList = this.huList.slice(index * 4, index * 4 + 4)
    },
    handleChangeDanyuan() {
      this.fetchHuInfo(this.wangge_number, this.lou_number, this.selectDanyuanNumber)
    },
    async fetchHuInfo(wangge_number, lou_number, danyuan_number) {
      this.loading = true
      await shequApi.getHuInfo({ wangge_number, lou_number, danyuan_number }).then(res => {
        this.huList = res.data
        this.huPage.page = Math.ceil(this.huList.length / 4)
        this.showHuList = this.huList.slice(0, 4)
        this.huPage.current = 0
        this.danyuan.canji = 0
        this.danyuan.duju = 0
        this.danyuan.imp = 0
        this.danyuan.kongchao = 0
        this.danyuan.no_people = 0
        this.danyuan.sick = 0
        this.huList.forEach(item => {
          this.danyuan.canji += item.canji
          this.danyuan.duju += item.duju
          this.danyuan.imp += item.imp
          this.danyuan.kongchao += item.kongchao
          this.danyuan.no_people += item.no_people
          this.danyuan.sick += item.sick
        })
      }).finally(_ => { this.loading = false })
    },
    async show(wangge_number, lou_number) {
      this.wangge_number = wangge_number
      this.lou_number = lou_number
      this.visible = true
      this.loading = true
      shequApi.getLouInfo({ wangge_number, lou_number }).then(async res => {
        console.log(res)
        this.resData = res.data
        this.danyuanArray = res.data.danyuan_array
        if (res.data.danyuan_array.length > 0) {
          this.selectDanyuanNumber = res.data.danyuan_array[0].number
          await this.fetchHuInfo(wangge_number, lou_number, res.data.danyuan_array[0].number)
        }
      }).finally(_ => { this.loading = false })
    }
  }
}
</script>

<style scoped>
.right-box {
  position: absolute;
  z-index: 99999;
  right: 40px;
  top: calc(10px + 107px);
  width: 348px;
  box-sizing: border-box;
  background-image: url('../../../assets/image/oneMap/right-box.png');
  background-size: 100% 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;

}
.right-content {
  box-sizing: border-box;
  padding-left: 37px;
  padding-right: 32px;
  width: 100%;
  overflow-y: overlay;
  max-height: calc(100vh - 137px);
}
.r-title {
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
}
.r-top1-content {
  width: 100%;
  height: 276px;
  background-image: url('../../../assets/image/oneMap/r-top1-content.png');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  background-size: 100% 100%;
}

.r-top1-row {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid #0097FF;
  line-height: 30px;
  display: flex;
  justify-content: space-between;

}
.r-top1-span1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.r-top1-row-last {
  border-bottom: unset;
}
.r-top1-row-title {
  font-weight: 600;
  color: #FFFFFF;
  font-size: 12px;
  padding-right: 5px;
}
.r-top1-row-text {
  color: #39C5FF;
  font-size: 12px;
}
.r-top1-span2 {
  flex: 1;
}
.r-top2-tab {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: space-between;
  background-size: 100% 100%;
  background-image: url('../../../assets/image/oneMap/top2-tab-box.png');
}
.r-top2-content {
  width: 100%;
  height: 105px;
  background-size: 100% 100%;
  background-image: url('../../../assets/image/oneMap/top2-box.png');
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
}
.r-t2-t-item {
  flex: 1;
  position: relative;
  text-align: center;
  font-size: 14px;
  line-height: 38px;
  color: #39C5FF;
  cursor: pointer;
}
.r-t2-t-item:hover {
  color: #00FF8A;
}

.r-t2-t-item::after {
  position: absolute;
  content: '';
  right: 0px;
  top: 8px;
  height: 22px;
  width: 2px;
  background-color: #008aff;
}
.r-t2-t-item:nth-last-child(1)::after{
   background-color: unset;
}
.r-top3-tab {
  background-image: url('../../../assets/image/oneMap/right-top3-tab.png');
  width: 100%;
  height: 38px;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 38px;
  color: #FFFFFF;

}
.r-t3-t-item:first-child {
  color: #00FF8A;
}
.r-t3-t-item {
  text-align: center;
  flex: 1;
  font-size: 12px;
}
.r-t3-t-item1 {
  flex: 1.5;
}
.r-top3-row {
  margin-top: 5px;
  width: 100%;
  height: 38px;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 38px;
  color: #FFFFFF;
  background-image: url('../../../assets/image/oneMap/right-top3-box.png');
}
.right-content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.right-content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 2px;
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  background   : #0082c4;
}
.right-content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background   : #FFFFFF;
}
.my-select {
  width: 100%;
  background-color: unset;
  color: #39C5FF;
  text-align: center;
  font-size: 16px;
  border: unset !important;
  font-weight: 600;
}
.my-select:focus {
  border: unset;
   outline: 0;
}
select option {
  padding: 10px;
  background-color: #011859;
  color: #fff;
  font-size: 16px;
  border-color: #39C5FF;
}
.r-t3-bottom-box {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 32px;
  align-items: center;
}
.r-t3-bottom-item {
  width: 10px;
  height: 10px;
  background: #1D2088;
  border: 1px solid #258CC5;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.r-t3-bottom-item-s {
  background-color: #258CC5;
}
.r-top3-content {
  min-height: 167px;
}
.r-top3-box  {
  position: relative;
}
.rt3-left-btn {
  width: 11px;
  height: 21px;
  cursor: pointer;
  background-image: url('../../../assets/image/oneMap/btn-left.png');
  position: absolute;
  top: 145px;
  left: -20px;
}
.rt3-right-btn {
  width: 11px;
  height: 21px;
  cursor: pointer;
  background-image: url('../../../assets/image/oneMap/btn-right.png');
  position: absolute;
  top: 145px;
  right: -20px;
}
.close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url('../../../assets/image/oneMap/close.png');
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-size: 100% 100%;
}
</style>
