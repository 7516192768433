import request from '@/utils/request'

export function get(params) {
  return request({
    url: 'https://ali-weather.showapi.com/area-to-weather',
    method: 'get',
    params,
    headers: {
      Authorization: 'APPCODE 1ec3d071e9d744fea2ba7a18f5aa33f5'
    }
  })
}

export default {
  get
}
