<template>
  <div class="left-bottom-box">
    <MTitle title="矛盾调处情况" />
    <div ref="main1" class="content1" />
    <!-- <div class="content2">
      <div class="content2-item1">
        <div class="content2-title1">
          <span class="content2-title">0</span>
        </div>
        <div class="content2-title0">
          待确认
        </div>
      </div>
      <div class="content2-item3">
        <div class="content2-title3">
          <span class="content2-title">0</span>
        </div>
        <div class="content2-title0">
          待完结
        </div>
      </div>
      <div class="content2-item4">
        <div class="content2-title4">
          <span class="content2-title">0</span>
        </div>
        <div class="content2-title0">
          已完结
        </div>
      </div>
      <div class="content2-item2">
        <div class="content2-title2">
          <span class="content2-title">0</span>1``
        </div>
        <div class="content2-title0">
          已提升
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import MTitle from './MTitle.vue'
import * as echarts from 'echarts'
export default {
  components: { MTitle },
  props: {
    list: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      times: []

    }
  },
  mounted() {
    setTimeout(() => {
      this.initEcharts1()
    }, 2000)
  },
  methods: {
    initEcharts1() {
      var chartDom = this.$refs.main1
      var myChart = echarts.init(chartDom)
      var option = {
        // backgroundColor: '#0e1c47',
        legend: {
          align: 'left',
          right: '5%',
          top: '5%',
          textStyle: {
            color: '#fff',
            fontSize: 14
          },
          icon: 'rect',
          itemGap: 25,
          itemWidth: 30,
          itemHeight: 8,
          data: [
            '待确认', '待完结', '已完结', '已提升'
          ]
        },
        grid: {
          top: '18%',
          left: '15%',
          right: '12%',
          bottom: '25%'
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              padding: 16,
              fontSize: 14
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: [this.list[0].num[3].y + '-' + this.list[0].num[3].m, this.list[0].num[2].y + '-' + this.list[0].num[2].m, this.list[0].num[1].y + '-' + this.list[0].num[1].m, this.list[0].num[0].y + '-' + this.list[0].num[0].m]
        }
        ],
        yAxis: [
          {
            name: '', // Y轴左侧单位
            nameTextStyle: {
              color: '#fff',
              fontSize: 16,
              padding: [0, 40, 20, 0]
            },
            min: 0,
            max: 6,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#7c9bb7',
                type: 'dashed'
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
                padding: 16
              }
            },
            axisTick: {
              show: false
            }
          },
          {
            type: '', // Y周右侧
            name: '',
            nameTextStyle: {
              color: '#fff',
              fontSize: 30,
              padding: [0, 0, 25, 20]
            },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              show: false,
              color: '#fff',
              fontSize: 30,
              padding: [10, 0, 0, 0]
            },
            splitLine: { show: false }
          }
        ],
        series: [
          {
            name: '待确认',
            type: 'line',
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 5,
                color: '#ffbc46'
              },
              borderColor: 'rgba(0,0,0,.4)'
            },
            itemStyle: {
              color: '#ffbc46'
            },
            tooltip: {
              show: true
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(255, 188, 70, 0.5)'
                }, {
                  offset: 1, color: 'rgba(255, 188, 70, 0)'
                }],
                global: false
              }
            },
            data: [this.list[0].num[3].num, this.list[0].num[2].num, this.list[0].num[1].num, this.list[0].num[0].num]
          },
          {
            name: '待完结',
            type: 'line',
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
                color: '#52fdeb'
              }
            },
            itemStyle: {
              color: '#52fdeb'
            },
            tooltip: {
              show: true
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(82, 253, 235, 0.5)'
                }, {
                  offset: 1, color: 'rgba(82, 253, 235, 0)'
                }],
                global: false
              }
            },
            data: [this.list[1].num[3].num, this.list[1].num[2].num, this.list[1].num[1].num, this.list[1].num[0].num]
          },
          {
            name: '已完结',
            type: 'line',
            // yAxisIndex: 1,
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
                color: '#8ed1fb'
              }
            },
            itemStyle: {
              color: '#8ed1fb'
            },
            tooltip: {
              show: true
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(142, 209, 251, 0.5)'
                }, {
                  offset: 1, color: 'rgba(142, 209, 251, 0)'
                }],
                global: false
              }
            },
            data: [this.list[2].num[3].num, this.list[2].num[2].num, this.list[2].num[1].num, this.list[2].num[0].num]
          },
          {
            name: '已提升',
            type: 'line',
            // yAxisIndex: 1,
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
                color: '#EE6969'
              }
            },
            itemStyle: {
              color: '#EE6969'
            },
            tooltip: {
              show: true
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(142, 209, 251, 0.5)'
                }, {
                  offset: 1, color: 'rgba(142, 209, 251, 0)'
                }],
                global: false
              }
            },
            data: [this.list[3].num[3].num, this.list[3].num[2].num, this.list[3].num[1].num, this.list[3].num[0].num]
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.left-bottom-box {
  width: 400px;
  position: absolute;
  top: 600px;
  left: 26px;
  pointer-events:visible;
  z-index: 99999;
}
.content1 {
  position: relative;
  left: -35px;
  width: 460px;
  height: 404px;
}
.content1-title1 {
  position: absolute;
  top: 70px;
  left: 153px;
  width: 100px;
  text-align: center;
  line-height: 43px;
  height: 43px;
  color: #22EBC0;
  font-size: 12px;
  font-weight: bold;
  z-index: 99999;
}
.content1-title2 {
  font-size: 12px;
  color: #BAE6FF;
  width: 148px;
  height: 28px;
  position: absolute;
  line-height: 29px;
  top: 7px;
  text-align: center;
}
.content1-title3 {
 font-size: 12px;
  color: #BAE6FF;
  width: 148px;
  height: 28px;
  position: absolute;
  line-height: 29px;
  top: 75px;
  left: -10px;
  text-align: center;
}
.content1-title4 {
 font-size: 12px;
  color: #BAE6FF;
  width: 148px;
  height: 28px;
  position: absolute;
  line-height: 29px;
  left: 20px;
  top: 143px;
  text-align: center;
}
.content1-title5 {
 font-size: 12px;
  color: #BAE6FF;
  width: 148px;
  height: 28px;
  position: absolute;
  line-height: 29px;
  top: 7px;
  right: 10px;
  text-align: center;
}
.content1-title6 {
 font-size: 12px;
  color: #BAE6FF;
  width: 148px;
  height: 28px;
  position: absolute;
  line-height: 29px;
  right: -10px;
  top: 75px;
  text-align: center;
}
.content1-title7 {
font-size: 12px;
  color: #BAE6FF;
  width: 148px;
  height: 28px;
  position: absolute;
  line-height: 29px;
  top: 143px;
  text-align: center;
  right: 5px;
}
.content2 {
  display: flex;
  justify-content: space-between;
  /* margin-top: 28px; */
}
.content2-item1 {
  width: 90px;
  height: 90px;
  background-image: url('../../../assets/image/home/left-bottom-content2.png');
  font-size: 12px;
  background-size: 100% 100%;
  color: #B2E3FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content2-item2 {
  width: 90px;
  height: 90px;
  background-image: url('../../../assets/image/home/left-bottom-content2.png');
  font-size: 12px;
  color: #B2E3FF;
  display: flex;
  background-size: 100% 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content2-item3 {
  width: 90px;
  height: 90px;
  background-size: 100% 100%;
  background-image: url('../../../assets/image/home/left-bottom-content2.png');
  font-size: 12px;
  color: #B2E3FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content2-item4 {
  width: 90px;
  height: 90px;
  background-size: 100% 100%;
  background-image: url('../../../assets/image/home/left-bottom-content2.png');
  font-size: 12px;
  color: #B2E3FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content2-title {
  font-size: 20px;
  font-weight: 600;
}
.content2-title1 {
  color: #536fc6;
}
.content2-title2 {
  color: #92cc76;
}
.content2-title3 {
  color: #fbd06f;
}
.content2-title4 {
  color: #ee6c6c;
}
.content2-title0 {
  height: 25px;
}
.content3 {
  width: 400px;
  background-size: 100% 100%;
  height: 47px;
  background-image: url('../../../assets/image/home/left-bottom-content3.png');
  margin-top: 28px;
  display: flex;
}
.content3-title1 {
  font-size: 14px;
  color: #ffffff;
  height: 47px;
  line-height: 54px;
  margin-left: 40px;
}
.content3-title2 {
  color: #22F9B6;
  font-size: 22px;
  line-height: 48px;
  margin-left: 30px;
}
@keyframes animation1{
  0% {
    top: -5px;
    left: 76px;
  }
  5% {
    top: -10px;
    left: 76px;
  }
  33% {
    top: -10px;
    left: 146px;
  }
  66% {
    top: 18px;
    left: 172px;
  }
  85% {
     opacity: 1
  }
  100% {
    top: 54px;
    left: 172px;
     opacity: 0.3
  }
}
.content1-point1 {
  top: 3px;
  left: 84px;
  position: absolute;
  width: 25px;
  height: 25px;
  background-image: url('../../../assets/image/home/point.png');
  background-size: 100% 100%;
  -webkit-animation:  animation11.5s;
  animation: animation1 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes animation2{
  0% {
    top: -5px;
    right: 66px;
  }
  5% {
    top: -10px;
    right: 66px;
  }
  33% {
    top: -10px;
    right: 139px;
  }
  66% {
    top: 18px;
    right: 165px;
  }
  85%{
     opacity: 1
  }
  100% {
    top:  54px;
     right: 165px;
      opacity: 0.3
  }
}
.content1-point2 {
  top: -5px;
  right: 66px;
  position: absolute;
   width: 25px;
  height: 25px;
  background-image: url('../../../assets/image/home/point.png');
  background-size: 100% 100%;
  -webkit-animation:  animation2 1.5s;
  animation: animation2 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes animation3{
  0% {
    bottom: -1px;
    right: 68px;
  }
  5% {
    bottom: -10px;
    right: 68px;
  }
  33% {
     bottom: -10px;
    right: 137px;
  }
  66% {
   bottom: 20px;
    right: 165px;
  }
  85% {
    opacity: 1
  }
  100% {
    bottom: 51px;
    right: 165px;
    opacity: 0.3
  }
}
.content1-point3 {
  bottom: -1px;
    right: 68px;
  position: absolute;
   width: 25px;
  height: 25px;
  background-image: url('../../../assets/image/home/point.png');
  background-size: 100% 100%;
  -webkit-animation:  animation3 1.5s;
  animation: animation3 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes animation4{
  0% {
    bottom: -1px;
    left: 76px;
  }
  5% {
    bottom: -10px;
    left: 76px;
  }
  33% {
    bottom: -10px;
    left: 146px;
  }
  66% {
    bottom: 20px;
    left: 172px;
  }
  85% {
    opacity: 1
  }
  100% {
    bottom: 51px;
    left: 172px;
    opacity: 0.3
  }
}
.content1-point4 {
  bottom: -1px;
  left: 76px;
  position: absolute;
   width: 25px;
  height: 25px;
  background-image: url('../../../assets/image/home/point.png');
  background-size: 100% 100%;
  -webkit-animation:  animation4 1.5s;
  animation: animation4 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes animation5{
  0% {
    bottom: 81px;
     left: 100px;
  }
   85% {
    opacity: 1
  }
  100% {
    bottom: 81px;
    left: 140px;
    opacity: 0.3
  }
}
.content1-point5 {
  bottom: 81px;
  left: 100px;
  position: absolute;
   width: 25px;
  height: 25px;
  background-image: url('../../../assets/image/home/point.png');
  background-size: 100% 100%;
  -webkit-animation:  animation5 1.5s;
  animation: animation5 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes animation6{
  0% {
    bottom: 81px;
    right: 90px;
  }
   85% {
    opacity: 1
  }
  100% {
    bottom: 81px;
    right: 135px;
     opacity: 0.3
  }
}
.content1-point6 {
  bottom: 81px;
  right: 100px;
  position: absolute;
   width: 25px;
  height: 25px;
  background-image: url('../../../assets/image/home/point.png');
  background-size: 100% 100%;
  -webkit-animation:  animation6 1.5s;
  animation: animation6 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@media only screen and (min-height: 1080px) {
 .left-bottom-box {
    width: 400px;
    position: absolute;
    top: 640px;
    left: 26px;
  }
}
</style>
