<template>
  <div v-if="loading" :class="{'fullscreen-loading': true, 'small-loading': small}">
    <div style="position:relative;">
      <img src="@/assets/image/loading.png" class="loader">
      <div class="loading-txt">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullscreenLoading',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: () => '加载中...'
    }
  }
}
</script>

<style>
.fullscreen-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.small-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader {
  animation: spin 2s linear infinite;
  width: 164px;
  height: 170px;
}
.loading-txt{
  text-align: center;
  position:absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 170px;
  top: 0px;
  font-size: 18px;
  font-weight: 400;
  color:transparent;
  background: linear-gradient(180deg, rgba(15, 227, 255, 1) 0%, rgba(201, 249, 255, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}
</style>
