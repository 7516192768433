import { render, staticRenderFns } from "./OneMapDakaBox.vue?vue&type=template&id=72973950&scoped=true&"
import script from "./OneMapDakaBox.vue?vue&type=script&lang=js&"
export * from "./OneMapDakaBox.vue?vue&type=script&lang=js&"
import style0 from "./OneMapDakaBox.vue?vue&type=style&index=0&id=72973950&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72973950",
  null
  
)

export default component.exports