import { render, staticRenderFns } from "./OneMapRightBox.vue?vue&type=template&id=36cb1362&scoped=true&"
import script from "./OneMapRightBox.vue?vue&type=script&lang=js&"
export * from "./OneMapRightBox.vue?vue&type=script&lang=js&"
import style0 from "./OneMapRightBox.vue?vue&type=style&index=0&id=36cb1362&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36cb1362",
  null
  
)

export default component.exports