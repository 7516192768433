<template>
  <div class="left-top">
    <m-title title="户籍人口" />
    <div class="content">
      <div class="row row1">
        <div class="row-item row-item1">
          <span class="row-title1">网格总数:</span>
          <span class="row-value1">{{ infoItem.wangge_num }}个</span>
        </div>
        <div class="row-item">
          <span class="row-title2">林茂常住人口:</span>
          <span class="row-value2">{{ infoItem.ren_num }}人</span>
        </div>
      </div>
      <vue-seamless-scroll :data="list" :class-option="classOption" class="warp">
        <div v-for="(item,index) in list" :key="index" class="row">
          <div class="row-item">
            <span class="row-title1">{{ item.number }}总户数:</span>
            <span class="row-value">{{ item.hu_num }} 户</span>
          </div>
          <div class="row-item">
            <span class="row-title2">{{ item.number }}总人口:</span>
            <span class="row-value">{{ item.num }} 人</span>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import MTitle from './MTitle.vue'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: { MTitle, vueSeamlessScroll },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    infoItem: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      // list: [
      //   { name: '网格一', homeNum: 357, peopleNum: 632 },
      //   { name: '网格二', homeNum: 318, peopleNum: 515 },
      //   { name: '网格三', homeNum: 366, peopleNum: 635 },
      //   { name: '网格四', homeNum: 326, peopleNum: 659 },
      //   { name: '网格五', homeNum: 360, peopleNum: 629 },
      //   { name: '网格六', homeNum: 372, peopleNum: 563 },
      //   { name: '网格七', homeNum: 343, peopleNum: 592 },
      //   { name: '网格八', homeNum: 304, peopleNum: 627 },
      //   { name: '网格九', homeNum: 313, peopleNum: 585 }
      // ],
      showList: [],
      classOption: {
        step: 0.5,
        hoverStop: true,
        loop: true
      }
    }
  }
}
</script>

<style scoped>
.warp {
  overflow: hidden;
}
.left-top{
  width: 400px;
  height: 320px;
  position: absolute;
  top: 100px;
  left: 26px;
}

.content {
  width: 100%;
  height:400px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
}
.row {
  display: flex;
  height: 52px;
  line-height: 52px;
  border-bottom: 2px dashed #3268dc;
}
.row1 {
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid #3268dc;
  margin-bottom: 10px;
}
.row-item {
  width: 50%;
  font-size: 14px;
  color: #39C5FF;
  display: flex;
}
.row-title1 {
  width: 100px;
  display: block;
}
.row-title2 {
  width: 100px;
  display: block;
}
.row-value {
  font-size: 16px;
  color: #F4F4F4;
  font-weight: bold;
}
.row-value1 {
   font-size: 16px;
    font-weight: bold;
  color: #EFF139;
}
.row-value2 {
  font-size: 16px;
    font-weight: bold;
  color: #1DF3C3;
}
@media only screen and (min-height: 1080px) {
  .left-top{
    width: 400px;
    height: 380px;
    position: absolute;
    top: 130px;
    left: 26px;
  }
}
</style>
