<template>
  <div class="top">
    <div class="left">
      <div :class="{'top-item': true, 'top-item-select': current === 2}" @click="handleView(2)">党建引领</div>
      <div :class="{'top-item': true, 'top-item-select': current === 1}" @click="handleSelect(1)">社区3D地图</div>
      <!--  <div :class="{'top-item': true, 'top-item-select': current === 3}" @click="handleSelect(3)">城镇数据一张图</div> -->
    </div>
    <!-- <div class="right">
      <div :class="{'top-item': true, 'top-item-select': current === 4}" @click="handleSelect(4)">城镇应急管理</div>
      <div :class="{'top-item': true, 'top-item-select': current === 5}" @click="handleSelect(5)">乡村网格化管理</div>
      <div :class="{'top-item': true, 'top-item-select': current === 6}" @click="handleSelect(6)">生态资源数字化</div> -->
    <!-- </div> -->
    <div class="title" @click="handleReturnHome">
      林茂社区智慧网格系统
    </div>
    <center ref="CenterBox" @on-close="handleCenterClose" />
  </div>
</template>

<script>
import Center from './Center.vue'
export default {
  components: { Center },
  data() {
    return {
      current: 0
    }
  },
  methods: {
    handleReturnHome() {
      this.$router.push({
        path: '/'
      })
      this.current = 0
    },
    handleSelect(item) {
      this.current = item
      switch (item) {
        case 1: this.$router.push({ path: '/oneMap' }); break
        // case 2: this.$router.push({ path: '/home' }); break
        // case 3: this.$router.push({ path: '/oneMap' }); break
        // case 4: this.$router.push({ path: '/townManager' }); break
        // case 5: this.$router.push({ path: '/reseauManager' }); break
        // case 6: this.$router.push({ path: '/ecology' }); break
      }
    },
    handleView(item) {
      this.$refs.CenterBox.show()
      this.current = item
      switch (item) {
        case 1: this.$router.push({ path: '/oneMap' }); break
        case 2: this.$router.push({ path: '' }); break
        // case 3: this.$router.push({ path: '/oneMap' }); break
        // case 4: this.$router.push({ path: '/townManager' }); break
        // case 5: this.$router.push({ path: '/reseauManager' }); break
        // case 6: this.$router.push({ path: '/ecology' }); break
      }
    },
    handleCenterClose() {
      this.current = 0
    }
  }
}
</script>

<style scoped>
.title {
  cursor: pointer;
  color: #ffffff;
  font-size: 32px;
  position: absolute;
  width: 400px;
  text-align: center;
  top: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  left: 760px;
}
.top {
  z-index: 99999;
  width: 1920px;
  position: absolute;
  top: 0;
  left: 0;
  height: 107px;
  background-image: url('../../assets/image/home/daohang.png');
}
.left {
  position: absolute;
  left: 150px;
  display: flex;
  color: #00F6FF;
  font-size: 20px;
  height: 44px;
  line-height: 44px;
  top: 33px;
  justify-content: space-between;
}
.top-item {
  text-align: center;
  cursor: pointer;
  height: 44px;
  width: 180px;
}
.top-item:hover {
  background-image: url('../../assets/image/home/select-back.png');
  background-size: 100% 100%;
  color: #ffffff;
}
.top-item-select {
  background-image: url('../../assets/image/home/select-back.png');
  background-size: 100% 100%;
  color: #ffffff;
}
</style>
