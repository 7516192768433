<template>
  <div v-if="visible" class="right-box">
    <loading :loading="loading" :small="true" />
    <div class="right-content">
      <div class="close-btn" @click="handleClose" />
      <div class="r-top1-box">
        <div class="r-title">
          <iframe class="my-frame" src="https://www.720yun.com/vr/f01jOpyOvy6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
export default {
  components: { Loading },
  data() {
    return {
      visible: false,
      loading: false
    }
  },
  created() {
  },
  methods: {
    handleClose() {
      this.visible = false
      this.$emit('on-close')
    },
    show(data) {
      this.visible = true
    }
  }
}
</script>

    <style scoped>
    .right-box {
      position: absolute;
      z-index: 99999;
      right: 40px;
      top: calc(10px + 157px);
      left: 450px;
      width:999px;
      height: 700px;
      box-sizing: border-box;
      background-image: url('../../assets/image/home/bg-view.png');
      background-size: 100% 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 5px;
    }
    .right-content {
      box-sizing: border-box;
      padding-left: 27px;
      padding-right: 32px;
      width: 100%;
      padding-top: 35px;
    }
    .r-title {
      position: absolute;
      top: 24px;
      left: 18px;
      width: 96.3%;
      overflow: hidden;
      height: 93.5%;
    }
    .right-content::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    .right-content::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 2px;
      box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
      background   : #0082c4;
    }
    .right-content::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      background   : #FFFFFF;
    }
    .close-btn {
      position: absolute;
      right: 34.5px;
      top: 38.5px;
      background-image: url('../../assets/image/home/close.svg');
      width: 31px;
      height: 32px;
      cursor: pointer;
      z-index: 999;
      background-size: 100% 100%;
    }

    .my-frame {
     width: 100%;
     height: 100%;
     border: unset;
    }
    </style>

