import request from '@/utils/request'

export function pageList(params) {
  return request({
    url: '/admin/layer-item/pageList',
    method: 'get',
    params
  })
}

export function list(params) {
  return request({
    url: '/admin/layer-item/list',
    method: 'get',
    params
  })
}

export default {
  pageList,
  list
}
