<template>
  <div class="right-bottom-box">
    <m-title title="宣传片" />
    <div class="content">
      <video src="@/assets/linmao.mp4" controls />
    </div>
  </div>
</template>

<script>
import MTitle from './MTitle.vue'
export default {
  components: { MTitle },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
.right-bottom-box {
  position: absolute;
  top: 355px;
  right: 26px;
  height: 300px;
  width: 400px;
  pointer-events:visible;
  z-index: 99999;
}
.tab-title {
  color: #0082C4;
  font-size: 15px;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  line-height: 30px;
}
.content {
  box-sizing: border-box;
  padding-right: 14px;
  padding-left: 11px;
  height: calc(100% - 65px);
}
.content video {
  width: 100%;
  height: 100%;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.item {
  width: 180px;
  border-radius: 10px;
  height: 60px;
  font-weight: 900;
  line-height: 55px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  color: white;

  background-color: rgba(29, 52, 82,0.5);
}
.item1 {
  border: 3px solid #92cc76;
}
.item2 {
    border: 3px solid #fbd06f;
}
.item3 {
   border: 3px solid #ee6969;
}
.item4 {
   border: 3px solid #536fc6;
}
@media only screen and (min-height: 1080px) {
 .right-bottom-box {
   position: absolute;
  top: 445px;
  right: 26px;
  height: 300px;
  width: 400px;
}
}
</style>
