<template>
  <div class="title">
    {{ title }} <img class="title-icon" src="@/assets/image/home/text-right.png">
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped>
.title {
  width: 400px;
  height: 58px;
  background-image: url('../../../assets/image/home/title-back.png');
  font-size: 22px;
  color: #ffffff;
  line-height: 50px;
  padding-left: 28px;
}
.title-icon {
  margin-left: 5px;
}
</style>
