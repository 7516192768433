<template>
  <div class="home">
    <div class="center">
      <iframe class="my-frame" src="https://www.720yun.com/t/9daknei977w?scene_id=111374100" />
    </div>
    <div class="left">
      <left-top-box :list="gridInfo" :info-item="gridObject" />
      <left-bottom-box :list="contradictionLog" />
    </div>
    <div class="right">
      <right-top-box />
      <right-center-box :msg="msgInfo" />
      <right-bottom-box />
    </div>
  </div>
</template>

<script>
import shequApi from '@/api/shequApi'
import LeftBottomBox from './components/LeftBottomBox.vue'
import LeftTopBox from './components/LeftTopBox.vue'
import RightBottomBox from './components/RightBottomBox.vue'
import RightCenterBox from './components/RightCenterBox.vue'
import RightTopBox from './components/RightTopBox.vue'
export default {
  components: { LeftTopBox, LeftBottomBox, RightTopBox, RightCenterBox, RightBottomBox },
  data() {
    return {
      gridInfo: [],
      gridObject: {},
      contradictionLog: [],
      msgInfo: [],
      msgList: [
        {
          name: '',
          value: undefined
        },
        {
          name: '',
          value: undefined
        },
        {
          name: '',
          value: undefined
        },
        {
          name: '',
          value: undefined
        }
      ]
    }
  },
  created() {
    shequApi.getHomeInfo().then(res => {
      this.gridInfo = res.data.grid_info.info
      this.gridObject = res.data.grid_info
      this.msgInfo = res.data.msg_info
      this.contradictionLog = res.data.contradiction_log
    })
    for (let index = 0; index < this.msgInfo.length; index++) {
      this.msgInfo[index].status_name = this.msgList[index].name
      this.msgInfo[index].num = this.msgList[index].value
    }
  }

}
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
}
.center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.left {
  z-index: 99998;
  width: 600px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url('../../assets/image/home/left-back.png');
  pointer-events: none;
}
.right {
  z-index: 99998;
  width: 600px;
  height: 102%;
  position: absolute;
  top: -10px;
  right: 0px;
  background-image: url('../../assets/image/home/right-back.png');
  pointer-events: none;
}
.my-frame {
  width: 100%;
  height: 100%;
  border: unset;
}
</style>
