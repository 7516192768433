<template>
  <div class="right-top-box">
    <m-title title="天气情况" />
    <div class="content">
      <div class="top">
        <div class="top-left">
          <!-- <img class="weather-img" :src="weatherData.now.weather_pic"> -->
          <img class="weather-img" :src="require('../../../assets/image/weather/'+ weatherData.now.weather_code + '.png')">
        </div>
        <div class="top-center">
          {{ weatherData.now.temperature }}<span style="font-size:30px">℃</span>
        </div>
        <div class="top-right">
          {{ weatherData.now.weather }}
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-item">
          <div class="bottom-item-value">
            {{ weatherData.now.sd }}
          </div>
          <div class="bottom-item-icon">
            <img src="../../../assets/image/home/sd.png"> 湿度
          </div>
        </div>
        <div class="bottom-item">
          <div class="bottom-item-value">
            {{ weatherData.now.aqi }}
          </div>
          <div class="bottom-item-icon">
            <img src="../../../assets/image/home/aqi.png"> AQI
          </div>
        </div>
        <div class="bottom-item">
          <div class="bottom-item-value">
            {{ weatherData.now.aqiDetail.pm2_5 }}
          </div>
          <div class="bottom-item-icon">
            <img src="../../../assets/image/home/PM2.5.png"> PM2_5
          </div>
        </div>
        <div class="bottom-item">
          <div class="bottom-item-value">
            {{ weatherData.now.wind_direction }}
          </div>
          <div class="bottom-item-icon">
            <img src="../../../assets/image/home/fx.png"> 风向
          </div>
        </div>
        <div class="bottom-item">
          <div class="bottom-item-value">
            {{ weatherData.now.wind_power }}
          </div>
          <div class="bottom-item-icon">
            <img src="../../../assets/image/home/fs.png"> 风速
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTitle from './MTitle.vue'
import weatherApi from '@/api/weatherApi.js'
export default {
  components: { MTitle },
  data() {
    return {
      weatherData: null
    }
  },
  created() {
    const weatherTime = localStorage.getItem('weatherTime')
    if (weatherTime && parseInt(weatherTime) + 1800 > Date.parse(new Date()) / 1000) {
      const weatherData = JSON.parse(localStorage.getItem('weatherData'))
      console.log(weatherData)
      this.weatherData = weatherData
    } else {
      weatherApi.get({ area: '西岗区' }).then(res => {
        this.weatherData = res.showapi_res_body
        localStorage.setItem('weatherData', JSON.stringify(this.weatherData))
        localStorage.setItem('weatherTime', Date.parse(new Date()) / 1000)
      })
    }
  },
  methods: {

  }

}
</script>
<style scoped>
.right-top-box {
  width: 400px;
  height: 260px;
  position: absolute;
  top: 100px;
  right: 26px;
}
.top {
  width: 100%;
  height: 133px;
  display: flex;
}
.top-left {
  width: 159px;
  height: 100%;
  line-height: 130px;
  text-align: center;
}
.weather-img {
  width: 120px;
  height: 120px;
}
.top-center {
  width: 130px;
  font-size: 50px;
  margin-top: 43px;
  height: 70px;
  font-weight: bold;
  font-style: italic;
  line-height: 70px;
  color: #31D3F3;
  background: linear-gradient(0deg, #007EFF 0%, #3FFFEA 63.2568359375%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.top-right {
  font-size: 20px;
  color: #F5FDFD;
  margin-top: 63px;
  height: 52px;
  line-height: 52px;
}
.bottom {
  height: 79px;
  justify-content: space-between;
  display: flex;
}
.bottom-item-value {
  color: #31D3F3;
  font-size: 19px;
  background: linear-gradient(0deg, #007EFF 0%, #3FFFEA 63.2568359375%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.bottom-item {
  flex: 1;
}
.bottom-item-icon {
  font-size: 10px;
  color: #F5FDFD;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.bottom-item-icon img {
  width: 16px;
  height: 16px;
  transform: translateY(-2px);
}
@media only screen and (min-height: 1080px) {
  .right-top-box {
  width: 400px;
  height: 270px;
  position: absolute;
  top: 140px;
  right: 26px;
}
}
</style>
