<template>
  <div v-if="visible" class="right-box">
    <loading :loading="loading" :small="true" />
    <div class="right-content">
      <div class="content">
        <div class="size">{{ layerItemData.name }} </div>
        <div class="close-btn" @click="handleClose" />
      </div>
      <div class="r-top1-box">
        <div class="r-title">
          <img v-viewer :src="imgPreUrl + layerItemData.imgUrl" width="500px" height="360px">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
export default {
  components: { Loading },
  data() {
    return {
      visible: false,
      loading: false,
      selectDanyuanNumber: '',
      imgPreUrl: '',
      layerItemData: {}
    }
  },
  created() {
    this.getImgPreUrl()
  },
  methods: {
    handleClose() {
      this.visible = false
      this.$emit('on-close')
    },
    show(data) {
      this.visible = true
      this.loading = true
      this.layerItemData = data
      this.loading = false
    },
    getImgPreUrl() {
      this.imgPreUrl = window.location.href.split('#')[0] + 'prod-api/static/'
    }
  }
}
</script>

  <style scoped>
  .right-box {
    position: absolute;
    z-index: 99;
    right: 40px;
    top: calc(10px + 307px);
    left: 700px;
    width: 638px;
    height: 430px;
    box-sizing: border-box;
    background-image: url('../../../assets/image/oneMap/btn-show.png');
    background-size: 100% 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
  }
  .right-content {
    box-sizing: border-box;
    padding-left: 27px;
    padding-right: 32px;
    width: 100%;
  }
  .content{
    display: flex;
    height: 40px;
  }
  .size{
    width: 580px;
    color: #FFFFFF;
    text-align: center;
    margin-left: 10px;
    margin-top: 5px;
  }
  .r-title {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    height: 50px;
    /* line-height: 120px;
    font-weight: 600; */
  }
  .r-top1-content {
    width: 100%;
    height: 276px;
    background-image: url('../../../assets/image/oneMap/r-top1-content.png');
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    background-size: 100% 100%;
  }

  .r-top1-row {
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 1px solid #0097FF;
    line-height: 30px;
    display: flex;
    justify-content: space-between;

  }
  .r-top1-span1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .r-top1-row-last {
    border-bottom: unset;
  }
  .r-top1-row-title {
    font-weight: 600;
    color: #FFFFFF;
    font-size: 12px;
    padding-right: 5px;
  }
  .r-top1-row-text {
    color: #39C5FF;
    font-size: 12px;
  }
  .r-top1-span2 {
    flex: 1;
  }
  .r-top2-tab {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: space-between;
    background-size: 100% 100%;
    background-image: url('../../../assets/image/oneMap/top2-tab-box.png');
  }
  .r-top2-content {
    width: 100%;
    height: 105px;
    background-size: 100% 100%;
    background-image: url('../../../assets/image/oneMap/top2-box.png');
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
  }
  .r-t2-t-item {
    flex: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    color: #39C5FF;
    cursor: pointer;
  }
  .r-t2-t-item:hover {
    color: #00FF8A;
  }

  .r-t2-t-item::after {
    position: absolute;
    content: '';
    right: 0px;
    top: 8px;
    height: 22px;
    width: 2px;
    background-color: #008aff;
  }
  .r-t2-t-item:nth-last-child(1)::after{
     background-color: unset;
  }
  .r-top3-tab {
    background-image: url('../../../assets/image/oneMap/right-top3-tab.png');
    width: 100%;
    height: 38px;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 38px;
    color: #FFFFFF;

  }
  .r-t3-t-item:first-child {
    color: #00FF8A;
  }
  .r-t3-t-item {
    text-align: center;
    flex: 1;
    font-size: 12px;
  }
  .r-t3-t-item1 {
    flex: 1.5;
  }
  .r-top3-row {
    margin-top: 5px;
    width: 100%;
    height: 38px;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 38px;
    color: #FFFFFF;
    background-image: url('../../../assets/image/oneMap/right-top3-box.png');
  }
  .right-content::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .right-content::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 2px;
    box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
    background   : #0082c4;
  }
  .right-content::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background   : #FFFFFF;
  }
  .my-select {
    width: 100%;
    background-color: unset;
    color: #39C5FF;
    text-align: center;
    font-size: 16px;
    border: unset !important;
    font-weight: 600;
  }
  .my-select:focus {
    border: unset;
     outline: 0;
  }
  select option {
    padding: 10px;
    background-color: #011859;
    color: #fff;
    font-size: 16px;
    border-color: #39C5FF;
  }
  .r-t3-bottom-box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 32px;
    align-items: center;
  }
  .r-t3-bottom-item {
    width: 10px;
    height: 10px;
    background: #1D2088;
    border: 1px solid #258CC5;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  .r-t3-bottom-item-s {
    background-color: #258CC5;
  }
  .r-top3-content {
    min-height: 167px;
  }
  .r-top3-box  {
    position: relative;
  }
  .rt3-left-btn {
    width: 11px;
    height: 21px;
    cursor: pointer;
    background-image: url('../../../assets/image/oneMap/btn-left.png');
    position: absolute;
    top: 145px;
    left: -20px;
  }
  .rt3-right-btn {
    width: 11px;
    height: 21px;
    cursor: pointer;
    background-image: url('../../../assets/image/oneMap/btn-right.png');
    position: absolute;
    top: 145px;
    right: -20px;
  }
  .close-btn {
    position: absolute;
    right: 12px;
    top: 18px;
    background-image: url('../../../assets/image/oneMap/close.png');
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-size: 100% 100%;
  }
  </style>

