import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'video.js/dist/video-js.css' // 引入video.js的css
import hls from 'videojs-contrib-hls' // 播放hls流需要的插件
import Antd from 'ant-design-vue'

import './utils/filters'
import 'ant-design-vue/dist/antd.css'
import 'echarts-gl' // 引入echarts
import '@/utils/VueViewer'
import 'viewerjs/dist/viewer.css' // 图片预览器的样式
Vue.use(Antd)
Vue.use(hls)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
