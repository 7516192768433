const getters = {
  deviceList: state => state.requestData.deviceList,
  cameraList: state => state.requestData.cameraList,
  globalMap: state => state.map.map,
  globalView: state => state.map.view,
  rightCenter: state => state.component.rightCenter,
  centerMap: state => state.component.centerMap,
  eventDetailBox: state => state.component.eventDetailBox,
  statisticsBox: state => state.component.statisticsBox,
  imagePreview: state => state.component.imagePreview,
  imageContrast: state => state.component.imageContrast,
  rubbishBox: state => state.component.rubbishBox,
  patrolList: state => state.socketData.patrolList,
  patrolCount: state => state.socketData.patrolCount,
  workList: state => state.requestData.workList,
  workCount: state => state.requestData.workCount,
  workDayCount: state => state.requestData.workDayCount,
  eventCount: state => state.requestData.eventCount
}
export default getters
