import { render, staticRenderFns } from "./LeftBottomBox.vue?vue&type=template&id=61c89f60&scoped=true&"
import script from "./LeftBottomBox.vue?vue&type=script&lang=js&"
export * from "./LeftBottomBox.vue?vue&type=script&lang=js&"
import style0 from "./LeftBottomBox.vue?vue&type=style&index=0&id=61c89f60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c89f60",
  null
  
)

export default component.exports