<template>
  <div class="right-center-box">
    <m-title title="大家说吧" />
    <div ref="main" class="content" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import MTitle from './MTitle.vue'
export default {
  components: { MTitle },
  props: {
    msg: {
      type: Array,
      default: () => [
      ]
    }
  },
  data() {
    return {
      msgItem: []

    }
  },
  mounted() {
    setTimeout(() => {
      this.initECharts()
    }, 2500)
  },
  methods: {
    initECharts() {
      var chartDom = this.$refs.main
      var myChart = echarts.init(chartDom)
      console.log('msg', this.msg[0].num)
      var res1 = this.msg[0].num
      var res2 = this.msg[1].num
      var res3 = this.msg[2].num
      var res4 = this.msg[3].num
      function getOptions() {
        const d = '//img.isqqw.com/profile/upload/2023/10/16/f99ebbad-fdf9-4872-b2bc-cc09a454690f.png'
        const a = [
          {
            'other': '',
            'sort': 0,
            'value': res1,
            'key': '民生问题'
          },
          {
            'other': '',
            'sort': 0,
            'value': res2,
            'key': '政策问题'
          },
          {
            'other': '',
            'sort': 0,
            'value': res3,
            'key': '求助'
          },
          {
            'other': '',
            'sort': '',
            'value': res4,
            'key': '其他'
          }
        ]
        const u = new echarts.graphic.LinearGradient(
          0,
          1,
          0,
          0,
          [{
            offset: 0,
            color: '#54FF9F'
          },
          {
            offset: 1,
            color: '#008B45'
          }
          ],
          !1
        )
        const y = new echarts.graphic.LinearGradient(
          0,
          1,
          0,
          0,
          [{
            offset: 0,
            color: '#FFD700'
          },
          {
            offset: 1,
            color: '#8B6914'
          }
          ],
          !1
        )
        const q = new echarts.graphic.LinearGradient(
          0,
          1,
          0,
          0,
          [{
            offset: 0,
            color: '#00ffeb'
          },
          {
            offset: 1,
            color: '#00c0ff'
          }
          ],
          !1
        )
        const z = new echarts.graphic.LinearGradient(
          0,
          1,
          0,
          0,
          [{
            offset: 0,
            color: '#0078ff'
          },
          {
            offset: 1,
            color: '#1e00ff'
          }
          ],
          !1
        )
        const c = []
        const h = []
        a.forEach(item => {
          const {
            key
          } = item
          if (key === '民生问题') {
            c.push(u)
            h.push('#54FF9F')
          } else if (key === '政策问题') {
            c.push(y)
            h.push('#FFD700')
          } else if (key === '求助') {
            c.push(q)
            h.push('#00ffeb')
          } else if (key === '其他') {
            c.push(z)
            h.push('#0078ff')
          }
        })

        var e = {
          // backgroundColor: 'rgb(6 14 26)',
          color: c,
          color2: h,
          animation: !0,
          xAxis: {
            type: 'category',
            axisLabel: {
              margin: 11,
              fontSize: 16,
              color: 'white'
            },
            data: [],
            axisTick: {
              show: !1
            },
            axisLine: {
              show: !1
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: !1
            },
            axisLine: {
              show: !0,
              lineStyle: {
                type: 'dashed',
                color: 'rgba(0,204,255,0.3)'
              }
            },
            position: 'right',
            splitNumber: 3,
            splitLine: {
              show: !0,
              lineStyle: {
                type: 'dashed',
                color: 'rgba(0,204,255,0.3)'
              }
            }
          },
          grid: {
            show: !1,
            top: '18%',
            bottom: '11%',
            left: '2%',
            right: '5%'
          },
          series: [
            {
              name: '标签',
              type: 'pictorialBar',
              animation: !0,
              symbol: 'rect',
              symbolSize: ['32', '100%'],
              data: [],
              z: 2,
              symbolOffset: ['0', '-15']
            },
            {
              name: 'b',
              stack: 'amount',
              tooltip: {
                show: !1
              },
              type: 'pictorialBar',
              symbol: 'diamond',
              symbolSize: ['32', '15'], // 下方立柱
              symbolOffset: ['0', '-17'],
              symbolPosition: 'end',
              data: [],
              z: 3
            },
            {
              name: 'c',
              stack: 'amount',
              tooltip: {
                show: !1
              },
              type: 'pictorialBar',
              symbol: 'diamond',
              symbolSize: ['32', '15'],
              symbolOffset: ['0', '-7'],
              symbolPosition: 'start',
              data: [],
              z: 3
            },
            {
              name: 'd',
              tooltip: {
                show: !1
              },
              type: 'pictorialBar',
              symbol: 'rect',
              symbolSize: ['32', '100%'],
              data: [],
              z: 0,
              symbolOffset: ['0', '-15']
            },
            {
              name: 'd2',
              tooltip: {
                show: !1
              },
              type: 'pictorialBar',
              symbol: 'image://' + d,
              symbolSize: ['1', '100%'],
              data: [],
              z: 10,
              symbolOffset: ['0', '-6']
            },
            {
              name: 'e',
              stack: 'amount',
              tooltip: {
                show: !1
              },
              type: 'pictorialBar',
              symbol: 'diamond',
              symbolSize: ['32', '15'],
              symbolOffset: ['0', '-16'], // 上方柱盖
              symbolPosition: 'end',
              data: [],
              z: 0,
              label: {
                show: !0,
                position: 'top',
                padding: [0, 0, 2, 0],
                formatter: function(i) {
                  const n = i.dataIndex
                  let l = a[n].value
                  // const p = a[n].other
                  return (
                    (l = l > 9999 ? (l / 1e4).toFixed(1) + '' : l),
                    i.name !== ''
                      ? '{a|' +
                  l +
                  // `}
                  // {c|` +
                  '}'
                      : '{b|' +
                  l +
                  `}
{c|` +
                  // p +
                  '}'
                  )
                },
                rich: {
                  a: {
                    color: '#FFFFFF',
                    fontSize: 20,
                    fontWeight: 'bold',
                    align: 'center'
                  },
                  b: {
                    color: '#FFFFFF',
                    fontSize: 23,
                    fontWeight: 'bold',
                    align: 'center'
                  },
                  c: {
                    color: '#FFFFFF',
                    fontSize: 20,
                    fontWeight: 'bold',
                    align: 'center'
                  }
                },
                fontSize: 20,
                fontWeight: 'bold'
              }
            }
          ]
        }
        e.series[0].name = '数量'
        a.forEach((item, t) => {
          e.xAxis.data.push(item.key)
          e.series[0].data.push({
            value: Number(a[t].value),
            itemStyle: {
              color: e.color[t % e.color.length]
            },
            animation: !1
          })
          e.series[1].data.push({
            value: Number(a[t].value) + 4.5,
            itemStyle: {
              color: e.color[t % e.color.length]
            },
            animation: !1
          })
          e.series[2].data.push({
            value: 4.5,
            itemStyle: {
              color: e.color2[t % e.color2.length]
            },
            animation: !1
          })
          e.series[3].data.push({
            value: Number(a[0].value) * 1.3,
            itemStyle: {
              color: e.color2[t % e.color.length],
              opacity: 0.5
            },
            emphasis: {
              itemStyle: {
                color: e.color2[t % e.color.length]
              }
            },
            animation: !1
          })
          e.series[4].data.push({
            value: Number(a[0].value) * 1.3 + 4.5,
            itemStyle: {
              color: e.color2[t % e.color.length],
              opacity: 0.4
            },
            emphasis: {
              itemStyle: {
                color: e.color2[t % e.color.length]
              }
            },
            animation: !1
          })
          e.series[5].data.push({
            value: Number(a[0].value) * 1.3 + 4.5,
            itemStyle: {
              color: e.color2[t % e.color.length],
              opacity: 0.7
            },
            emphasis: {
              itemStyle: {
                color: e.color2[t % e.color.length]
              }
            },
            animation: !1
          })
        })
        return e
      }
      var option = getOptions()
      myChart.setOption(option)
    }
  }
}
</script>
<style scoped>
.right-center-box {
  position: absolute;
  top: 650px;
  right: 26px;
  height: 260px;
  width: 400px;
}
.content {
  width: 400px;
  height: 300px;
  position: absolute;
  top: 35px;
  right: 0px;
}
@media only screen and (min-height: 1080px) {
  .right-center-box {
    position: absolute;
    top: 750px;
    right: 26px;
    height: 270px;
    width: 400px;
  }
}
</style>
