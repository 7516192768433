<template>
  <div id="app">
    <top />
    <router-view />
  </div>
</template>
<script>
import Top from './views/components/Top.vue'
export default {
  components: { Top },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style>
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
*{
  user-select: none;
}
body {
  margin: 0;
  padding: 0;
}
.BMapLabel{
  background: unset !important;
  border: unset !important;
}
.anchorBL {
  display: none !important;
}
</style>
