const state = {
  rightCenter: {},
  centerMap: {},
  eventDetailBox: {},
  rubbishBox: {},
  statisticsBox: {},
  imagePreview: {},
  imageContrast: {}
}

const mutations = {
  SET_RIGHT_CENTER: (state, rightCenter) => {
    state.rightCenter = rightCenter
  },
  SET_CENTER_MAP: (state, centerMap) => {
    state.centerMap = centerMap
  }
}

const actions = {
  setRightCenter({ commit }, rightCenter) {
    commit('SET_RIGHT_CENTER', rightCenter)
  },
  setCenterMap({ commit }, centerMap) {
    commit('SET_CENTER_MAP', centerMap)
  },
  setEventDetailBox({ commit }, detailBox) {
    state.eventDetailBox = detailBox
  },
  setRubbishBox({ commit }, rubbishBox) {
    state.rubbishBox = rubbishBox
  },
  setImagePreview({ commit }, imagePreview) {
    state.imagePreview = imagePreview
  },
  setImageContrast({ commit }, imageContrast) {
    state.imageContrast = imageContrast
  },
  setStatisticsBox({ commit }, statisticsBox) {
    state.statisticsBox = statisticsBox
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
