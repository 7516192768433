<template>
  <div class="one-map">
    <div id="two-map-object" class="one-map-object" />
  </div>
</template>

<script>
import styleJson from '@/assets/map-style/bmap-style1.json'
import villageLayerJSON from './data/village-bianjie.json'
import chengshanJSON from './data/chengshan-bianjie.json'
import hourseData from './data/homeData.json'
import wangGeData from './data/wangge-bianji.json'
import luDengData from './data/ludeng.json'
export default {
  data() {
    return {
      map: null,
      view: null,
      villageLayer: null,
      hourseLayer: null,
      hourseLayerData: [],
      wangGeLayerData: []
    }
  },
  mounted() {
    this.initMap()
    this.showHourse()
    this.initBianjie()
    // this.initWangGe()
    this.initLuDeng()
  },
  methods: {
    initLuDeng() {
      // eslint-disable-next-line no-undef
      const mapLayerItem = new mapvgl.IconLayer({
        width: 10,
        height: 10,
        unit: 'm',
        offset: [0, -10 / 2],
        icon: require('@/assets/image/oneMap/ludeng.png'),
        enablePicked: true, // 是否可以拾取
        onClick: (e) => {
        }
      })
      this.view.addLayer(mapLayerItem)
      const mapLayerItemData = []

      luDengData.forEach(item => {
        mapLayerItemData.push({
          geometry: {
            type: 'Point',
            coordinates: JSON.parse(item.geoJson)
          },
          properties: {
            ...item
          }
        })
      })
      mapLayerItem.setData(mapLayerItemData)
    },
    initWangGe() {
      // eslint-disable-next-line no-undef
      var shaperLayer = new mapvgl.PolygonLayer({
        lineColor: 'rgb(252, 0, 255)',
        lineWidth: 4,
        fillColor: 'rgba(252, 0, 255,0.2)',
        data: []
      })
      // eslint-disable-next-line no-undef
      var shaperLayer2 = new mapvgl.ShapeLayer({
        color: 'rgba(252, 0, 255, 1)', // 面颜色
        style: 'gradual',
        opacity: 0.2 // 透明度
      })

      wangGeData.forEach((item, idx) => {
        this.wangGeLayerData.push({
          geometry: {
            type: 'Polygon',
            coordinates: [JSON.parse(item.geoJson)]
          },
          properties: {
            id: item.id,
            ...item.properties
          }
        })
      })
      const wangGeLayerData2 = []
      wangGeData.forEach((item, idx) => {
        wangGeLayerData2.push({
          geometry: {
            type: 'Polygon',
            coordinates: [JSON.parse(item.geoJson)]
          },
          properties: {
            id: item.id,
            ...item.properties,
            height: 20
          }
        })
      })

      this.view.addLayer(shaperLayer)
      this.view.addLayer(shaperLayer2)
      shaperLayer2.setData(wangGeLayerData2)
      shaperLayer.setData(this.wangGeLayerData)
    },
    showHourse() {
      // const that = this
      // eslint-disable-next-line no-undef
      this.hourseLayer = new mapvgl.ShapeLayer({
        useTopColor: true,
        enablePicked: true, // 是否可以拾取
        texture: require('@/assets/image/oneMap/wall2.png'),
        isTextureFull: false,
        textureScale: 0.07,
        topColor: 'rgba(0,4,141,0.8)',
        opacity: 1,
        fillOpacity: 1,
        onClick: (e) => { // 点击事件
          // if (e.dataIndex >= 0) {
          //   that.showEditView(e.dataItem)
          // }
        }
      })
      hourseData.forEach((item, idx) => {
        this.hourseLayerData.push({
          geometry: {
            type: 'Polygon',
            coordinates: [JSON.parse(item.geoJson)]
          },
          properties: {
            id: item.id,
            // ...JSON.parse(item.properties),
            height: JSON.parse(item.properties).height
          }
        })
      })
      this.hourseLayer.setData(this.hourseLayerData)
      this.view.addLayer(this.hourseLayer)
    },
    initBianjie() {
      const pts = []
      chengshanJSON.forEach(latlng => {
        // eslint-disable-next-line no-undef
        var pt = new BMapGL.Point(latlng[0], latlng[1])
        pts.push(pt)
      })
      this.map.setViewport(pts, { margins: [30, 30, 30, 30], enableAnimation: false })
      setTimeout(() => {
        this.map.setTilt(65)
      }, 2000)
      // const that = this
      // eslint-disable-next-line no-undef
      this.villageLayer = new mapvgl.PolygonLayer({
        lineColor: 'rgb(110, 201, 237)',
        lineWidth: 3,
        repeat: true,
        enablePicked: true,
        selectedColor: 'rgba(5, 143, 190,0.5)',
        fillColor: 'rgb(3, 45, 110,0.5)',
        data: []
        // onClick: (e) => { // 点击事件
        //   if (e.dataIndex >= 0) {
        //     that.handleStep1(e.dataItem.properties)
        //   }
        // }
      })
      const villageLayerData = []
      villageLayerJSON.features.forEach((item, index) => {
        villageLayerData.push({
          geometry: item.geometry,
          properties: {
            height: 0, // 多边形高度
            zIndex: index
          }
        })
      })
      this.villageLayer.setData(villageLayerData)
      // this.view.addLayer(this.villageLayer)
    },
    initMap() {
      // eslint-disable-next-line no-undef
      var map = new BMapGL.Map('two-map-object', {
        style: {
          styleJson: styleJson
        },
        restrictCenter: false
      })
      map.setDisplayOptions({
        building: false
      })

      // eslint-disable-next-line no-undef
      map.centerAndZoom(new BMapGL.Point(121.600356, 38.999081), 12)
      map.enableScrollWheelZoom(true)
      map.setDisplayOptions({
        indoorMaInfo: false,
        poiText: false, // 隐藏poi标注
        poiIcon: false // 隐藏poi图标
      })
      this.map = map
      // this.map.setMapType('B_SATELLITE_MAP')
      map.enableScrollWheelZoom(true)
      map.setDisplayOptions({
        indoor: false,
        poi: true,
        skyColors: [
          'rgba(5, 5, 30, 0.01)',
          'rgba(5, 5, 30, 1.0)'
        ]
      })
      // eslint-disable-next-line no-undef
      var view = new mapvgl.View({
        map: map
      })
      this.view = view
    }
  }
}
</script>

<style scoped>
.one-map {
  width: 100%;
  height: 100%;
}
.one-map-object {
  width: 100%;
  height: 100%;
}
</style>
