<!-- eslint-disable no-undef -->
<template>
  <div class="one-map">
    <div id="one-map-object" class="one-map-object" />
    <one-map-right-box ref="oneMapRightBox" @on-close="handleRightClose" />
    <one-map-center-box ref="OneMapCenterBox" @on-close="handleRightClose" />
    <one-map-daka-box ref="OneMapDakaBox" />
    <div class="left-box">
      <div class="left-box-content">
        <div
          v-for="(item, index) in layerList"
          :key="index"
          :class="{ 'left-box-item': true, 'left-box-item-s': item.select }"
          @click="handleSelectLayer(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import styleJson from '@/assets/map-style/bmap-style1.json'
import villageLayerJSON from './data/village-bianjie.json'
import chengshanJSON from './data/chengshan-bianjie.json'
import wangGeData from './data/wangge-bianji.json'
import layerApi from '@/api/layerApi'
import shequApi from '@/api/shequApi'
import internetApi from '@/api/InternetApi'
import OneMapRightBox from './components/OneMapRightBox.vue'
import OneMapCenterBox from './components/OneMapCenterBox.vue'
import OneMapDakaBox from './components/OneMapDakaBox.vue'
export default {
  components: { OneMapRightBox, OneMapCenterBox, OneMapDakaBox },
  data() {
    return {
      map: null,
      view: null,
      villageLayer: null,
      hourseLayer: null,
      selectHourseLayer: null,
      otherHourseLayer: null,
      otherHourseLayerData: [],
      hourseLayerData: [],
      wangGeLayerData: [],
      selectData: [],
      textLayer: null,
      houser: [],
      louParams: {
        current: 0,
        pages: 0,
        size: 500
      },
      layerList: [],
      LayerItemData: [],
      daKaList: []
    }
  },
  mounted() {
    this.initMap()
    this.showHourse()
    this.initSize()
    this.initBianjie()
    this.initWangGe()
    this.initLaji()
    this.initWeixian()
    this.initDaka()
    this.initSheBei()
    this.initHongqi()
    this.initSize()
    this.showViewPoints()
    this.fethData()
    this.showViewSiFa()
  },
  methods: {
    fethData() {
      shequApi.getDakaInfo().then((res) => {
        this.daKaList = res.data
      })
    },
    handleRightClose() {
      this.selectHourseLayer.setData([])
    },
    handleSelectLayer(item) {
      item.select = !item.select
      if (item.select) {
        item.layer.forEach((layer) => {
          this.view.addLayer(layer)
        })
      } else {
        item.layer.forEach((layer) => {
          this.view.removeLayer(layer)
        })
      }
    },
    showView(data) {
      this.selectData = data
      this.selectHourseLayer.setData([data])
      this.$refs.oneMapRightBox.show(
        data.properties.wangge_number,
        data.properties.number
      )
    },
    showViewLaJi(data) {
      this.LayerItemData = data.properties
      this.$refs.OneMapCenterBox.show(this.LayerItemData)
    },
    initDaka() {
      const that = this
      layerApi.list({ layerId: 29 }).then((res) => {
        // eslint-disable-next-line no-undef
        const mapLayerItem = new mapvgl.IconLayer({
          width: 11,
          height: 15,
          unit: 'm',
          offset: [0, -15 / 2],
          icon: require('@/assets/image/oneMap/daka.png'),
          enablePicked: true, // 是否可以拾取
          onClick: (e) => {
            if (e.dataIndex >= 0) {
              const itemList = []
              this.daKaList.forEach((item) => {
                if (item.address === e.dataItem.properties.name) {
                  itemList.push(item)
                }
              })
              const latestRecord = itemList.reduce(
                (accumulator, currentValue) => {
                  return accumulator.create_time > currentValue.create_time
                    ? accumulator
                    : currentValue
                }
              )
              that.$refs.OneMapDakaBox.show(latestRecord)
            }
          }
        })
        this.view.addLayer(mapLayerItem)
        const mapLayerItemData = []
        this.layerList.push({
          name: '小巷管家',
          layer: [mapLayerItem],
          select: true
        })
        res.data.forEach((item) => {
          mapLayerItemData.push({
            geometry: {
              type: 'Point',
              coordinates: JSON.parse(item.geoJson)
            },
            properties: {
              ...item
            }
          })
        })
        mapLayerItem.setData(mapLayerItemData)
      })
    },
    initWeixian() {
      const that = this
      layerApi.list({ layerId: 28 }).then((res) => {
        // eslint-disable-next-line no-undef
        const mapLayerItem = new mapvgl.IconLayer({
          width: 10,
          height: 10,
          unit: 'm',
          offset: [0, -10 / 2],
          icon: require('@/assets/image/oneMap/xqxd.png'),
          enablePicked: true, // 是否可以拾取
          onClick: (e) => {
            if (e.dataIndex >= 0) {
              that.showViewLaJi(e.dataItem)
            }
          }
        })
        this.view.addLayer(mapLayerItem)
        const mapLayerItemData = []
        this.layerList.push({
          name: '险情险段',
          layer: [mapLayerItem],
          select: true
        })
        res.data.forEach((item) => {
          mapLayerItemData.push({
            geometry: {
              type: 'Point',
              coordinates: JSON.parse(item.geoJson)
            },
            properties: {
              ...item
            }
          })
        })
        mapLayerItem.setData(mapLayerItemData)
      })
    },
    initSheBei() {
      const data = {
        deviceId: '',
        deviceImei: '866164079177785',
        fullFlag: 0
      }
      internetApi.getSheBeiList(data).then((res) => {})
    },
    initLaji() {
      const that = this
      layerApi.list({ layerId: 27 }).then((res) => {
        // eslint-disable-next-line no-undef
        const mapLayerItem = new mapvgl.IconLayer({
          width: 10,
          height: 10,
          unit: 'm',
          offset: [0, -10 / 2],
          icon: require('@/assets/image/oneMap/laji.png'),
          enablePicked: true, // 是否可以拾取
          onClick: (e) => {
            if (e.dataIndex >= 0) {
              that.showViewLaJi(e.dataItem)
            }
          }
        })
        this.view.addLayer(mapLayerItem)
        const mapLayerItemData = []
        this.layerList.push({
          name: '垃圾点',
          layer: [mapLayerItem],
          select: true
        })
        res.data.forEach((item) => {
          mapLayerItemData.push({
            geometry: {
              type: 'Point',
              coordinates: JSON.parse(item.geoJson)
            },
            properties: {
              ...item
            }
          })
        })
        mapLayerItem.setData(mapLayerItemData)
      })
    },
    initHongqi() {
      layerApi.list({ layerId: 32 }).then((res) => {
        // eslint-disable-next-line no-undef
        const mapLayerItem = new mapvgl.IconLayer({
          width: 10,
          height: 10,
          unit: 'm',
          offset: [0, -10 / 2],
          icon: require('@/assets/image/oneMap/flag.png'),
          enablePicked: true, // 是否可以拾取
          onClick: (e) => {}
        })
        this.view.addLayer(mapLayerItem)
        const mapLayerItemData = []
        res.data.forEach((item) => {
          mapLayerItemData.push({
            geometry: {
              type: 'Point',
              coordinates: JSON.parse(item.geoJson)
            },
            properties: {
              ...item
            }
          })
        })
        mapLayerItem.setData(mapLayerItemData)
      })
    },
    showViewPoints() {
      layerApi.list({ layerId: 35 }).then((res) => {
        // eslint-disable-next-line no-undef
        const mapLayerItem = new mapvgl.IconLayer({
          width: 6,
          height: 10,
          unit: 'm',
          offset: [0, -10 / 2],
          icon: require('@/assets/image/oneMap/point.png'),
          enablePicked: true, // 是否可以拾取
          onClick: (e) => {
            if (e.dataIndex >= 0) {
              var opts = {
                width: 40, // 信息窗口宽度
                height: 50, // 信息窗口高度
                title: '15分钟便民服务圈' // 信息窗口标题
              }
              /* eslint-disable */
              var point = new BMap.Point(
                e.dataItem.geometry.coordinates[0],
                e.dataItem.geometry.coordinates[1]
              );
              var infoWindow = new BMapGL.InfoWindow(
                e.dataItem.properties.name,
                opts
              );
              this.map.openInfoWindow(infoWindow, point);
            }
          },
        });
        this.view.addLayer(mapLayerItem);
        const mapLayerItemData = [];
        res.data.forEach((item) => {
          mapLayerItemData.push({
            geometry: {
              type: "Point",
              coordinates: JSON.parse(item.geoJson),
            },
            properties: {
              ...item,
            },
          });
        });
        mapLayerItem.setData(mapLayerItemData);
      });
    },
    showViewSiFa() {
      layerApi.list({ layerId: 48 }).then((res) => {
        // eslint-disable-next-line no-undef
        const mapLayerItem = new mapvgl.IconLayer({
          width: 20,
          height: 20,
          unit: "m",
          offset: [0, -10],
          icon: require("@/assets/oneMap/wujiao.svg"),
          enablePicked: true, // 是否可以拾取
          onClick: (e) => {
            if (e.dataIndex >= 0) {
              var opts = {
                width: 80, // 信息窗口宽度
                height: 85, // 信息窗口高度
                title: "立法征询专业小组成员", // 信息窗口标题
              };
              /* eslint-disable */
              var point = new BMap.Point(
                e.dataItem.geometry.coordinates[0],
                e.dataItem.geometry.coordinates[1]
              );
              var infoWindow = new BMapGL.InfoWindow(
                e.dataItem.properties.name,
                opts
              );
              this.map.openInfoWindow(infoWindow, point);
            }
          },
        });
        this.view.addLayer(mapLayerItem);
        const mapLayerItemData = [];
        res.data.forEach((item) => {
          mapLayerItemData.push({
            geometry: {
              type: "Point",
              coordinates: JSON.parse(item.geoJson),
            },
            properties: {
              ...item,
            },
          });
        });
        mapLayerItem.setData(mapLayerItemData);
      });
    },
    initSize() {
      layerApi.list({ layerId: 34 }).then((res) => {
        // eslint-disable-next-line no-undef
        const mapLayerItem = new mapvgl.TextLayer({
          color: "#ffff",
          fontSize: 12,
          fontFamily: "Microsoft Yahei",
          flat: false,
          collides: true,
          zIndex: 3,
          offset: [0, 0],
          // padding: [2, 2],
          // margin: [0, 0],
          onClick: (e) => {},
        });
        this.view.addLayer(mapLayerItem);

        const mapLayerItemData = [];
        res.data.forEach((item) => {
          mapLayerItemData.push({
            geometry: {
              type: "Point",
              coordinates: JSON.parse(item.geoJson),
            },
            properties: {
              id: item.id,
              ...JSON.parse(item.properties),
              height: JSON.parse(item.properties).height,
              text: item.name,
            },
          });
        });
        mapLayerItem.setData(mapLayerItemData);
      });
    },

    initWangGe() {
      // eslint-disable-next-line no-undef
      var shaperLayer = new mapvgl.PolygonLayer({
        lineColor: "rgb(252, 0, 255)",
        lineWidth: 4,
        fillColor: "rgba(252, 0, 255,0.2)",
        data: [],
      });
      // eslint-disable-next-line no-undef
      var shaperLayer2 = new mapvgl.ShapeLayer({
        color: "rgba(252, 0, 255, 1)", // 面颜色
        style: "gradual",
      });

      wangGeData.forEach((item, idx) => {
        this.wangGeLayerData.push({
          geometry: {
            type: "Polygon",
            coordinates: [JSON.parse(item.geoJson)],
          },
          properties: {
            id: item.id,
            ...item.properties,
            color: item.color, // 为每个网格块添加颜色值
          },
        });
      });
      const wangGeLayerData2 = [];
      wangGeData.forEach((item, idx) => {
        wangGeLayerData2.push({
          geometry: {
            type: "Polygon",
            coordinates: [JSON.parse(item.geoJson)],
          },
          properties: {
            id: item.id,
            ...item.properties,
            height: 100,
          },
        });
      });

      this.view.addLayer(shaperLayer);
      this.view.addLayer(shaperLayer2);
      shaperLayer2.setData(wangGeLayerData2);
      shaperLayer.setData(this.wangGeLayerData);
      this.layerList.push({
        name: "网格",
        layer: [shaperLayer, shaperLayer2],
        select: true,
      });
    },

    async showHourse() {
      let resData = [];
      let houseData = [];
      let otherData = [];
      this.louParams.current = 0;
      let itemRes;
      do {
        itemRes = await layerApi.pageList({ layerId: 19, ...this.louParams });
        this.louParams.current++;
        resData = resData.concat(itemRes.data.records);
        this.louParams.pages = itemRes.data.pages;
      } while (itemRes.data.records.length !== 0);
      houseData = resData.filter((item) => {
        const properties = JSON.parse(item.properties);
        if (properties.wangge_number && properties.number) {
          return true;
        } else {
          return false;
        }
      });
      otherData = resData.filter((item) => {
        const properties = JSON.parse(item.properties);
        if (properties.wangge_number && properties.number) {
          return false;
        } else {
          return true;
        }
      });
      const that = this;
      // eslint-disable-next-line no-undef
      this.selectHourseLayer = new mapvgl.ShapeLayer({
        useTopColor: true,
        enablePicked: true, // 是否可以拾取
        texture: require("@/assets/image/oneMap/wall/5.png"),
        isTextureFull: false,
        textureScale: 0.07,
        topColor: "rgba(0, 224, 111,0.8)",
        opacity: 1,
        fillOpacity: 1,
      });
      // eslint-disable-next-line no-undef
      this.otherHourseLayer = new mapvgl.ShapeLayer({
        useTopColor: true,
        color: "rgb(18, 34, 61)",
        topColor: "rgba(18, 34, 61,0.8)",
        opacity: 1,
        fillOpacity: 1,
        onClick: (e) => {
          // 点击事件
          if (e.dataIndex >= 0) {
            // that.showView(e.dataItem)
          }
        },
      });

      // eslint-disable-next-line no-undef
      this.hourseLayer = new mapvgl.ShapeLayer({
        useTopColor: true,
        enablePicked: true, // 是否可以拾取
        // texture: require('@/assets/image/oneMap/wall2.png'),
        texture: require("@/assets/image/oneMap/lou/6.png"),
        isTextureFull: false,
        textureScale: 0.07,
        // topColor: 'rgba(0, 4, 141,0.8)',
        topColor: "#84664A",
        opacity: 1,
        fillOpacity: 1,
        onClick: (e) => {
          // 点击事件
          if (e.dataIndex >= 0) {
            that.showView(e.dataItem);
          }
        },
      });
      otherData.forEach((item) => {
        this.otherHourseLayerData.push({
          geometry: {
            type: "Polygon",
            coordinates: [JSON.parse(item.geoJson)],
          },
          properties: {
            id: item.id,
            ...JSON.parse(item.properties),
            height: JSON.parse(item.properties).height,
          },
        });
      });
      houseData.forEach((item, idx) => {
        this.hourseLayerData.push({
          geometry: {
            type: "Polygon",
            coordinates: [JSON.parse(item.geoJson)],
          },
          properties: {
            id: item.id,
            ...JSON.parse(item.properties),
            height: JSON.parse(item.properties).height,
          },
        });
      });
      this.otherHourseLayer.setData(this.otherHourseLayerData);
      this.hourseLayer.setData(this.hourseLayerData);
      this.view.addLayer(this.hourseLayer);
      this.view.addLayer(this.otherHourseLayer);
      this.view.addLayer(this.selectHourseLayer);
      this.layerList.push({
        name: "楼宇",
        layer: [
          this.hourseLayer,
          this.otherHourseLayer,
          this.selectHourseLayer,
        ],
        select: true,
      });
    },
    initBianjie() {
      const pts = [];
      chengshanJSON.forEach((latlng) => {
        // eslint-disable-next-line no-undef
        var pt = new BMapGL.Point(latlng[0], latlng[1]);
        pts.push(pt);
      });
      this.map.setViewport(pts, {
        margins: [30, 30, 30, 30],
        enableAnimation: false,
      });
      setTimeout(() => {
        this.map.setTilt(65);
      }, 2500);
      // const that = this
      // eslint-disable-next-line no-undef
      this.villageLayer = new mapvgl.PolygonLayer({
        lineColor: "rgb(110, 201, 237)",
        lineWidth: 3,
        repeat: true,
        enablePicked: true,
        selectedColor: "rgba(5, 143, 190,0.5)",
        fillColor: "rgb(3, 45, 110,0.5)",
        data: [],
        // onClick: (e) => { // 点击事件
        //   if (e.dataIndex >= 0) {
        //     that.handleStep1(e.dataItem.properties)
        //   }
        // }
      });
      const villageLayerData = [];
      villageLayerJSON.features.forEach((item, index) => {
        villageLayerData.push({
          geometry: item.geometry,
          properties: {
            height: 0, // 多边形高度
            zIndex: index,
          },
        });
      });
      this.villageLayer.setData(villageLayerData);
      // this.view.addLayer(this.villageLayer)
    },
    initMap() {
      // eslint-disable-next-line no-undef
      var map = new BMapGL.Map("one-map-object", {
        style: {
          styleJson: styleJson,
        },
        restrictCenter: false,
      });
      map.setDisplayOptions({
        building: false,
      });

      const viewOptions = {
        heading: 180 // 旋转角度，范围为0到360，值越大地图顺时针旋转
      };

        // 应用视角设置
        map.setHeading(viewOptions.heading); // 设置旋转角度

      map.setMinZoom(18.3);

      // eslint-disable-next-line no-undef
      map.centerAndZoom(new BMapGL.Point(121.600356, 38.999081), 18.5);
      map.enableScrollWheelZoom(true);
      map.setDisplayOptions({
        indoorMaInfo: false,
        poiText: false, // 隐藏poi标注
        poiIcon: false, // 隐藏poi图标
      });
      this.map = map;
      // this.map.setMapType('B_SATELLITE_MAP')
      map.enableScrollWheelZoom(true);
      map.setDisplayOptions({
        indoor: false,
        poi: true,
        skyColors: ["rgba(5, 5, 30, 0.01)", "rgba(5, 5, 30, 1.0)"],
      });
      // eslint-disable-next-line no-undef
      var view = new mapvgl.View({
        map: map,
      });
      this.view = view;
    },
  },
};
</script>

<style scoped>
.one-map {
  width: 100%;
  height: 100%;
  position: relative;
}

.one-map-object {
  width: 100%;
  height: 100%;
}
.left-box {
  width: 232px;
  height: 317px;
  background-image: url("../../assets/image/oneMap/left-box.png");
  position: absolute;
  left: 3px;
  top: 373px;
  z-index: 99999;
  box-sizing: border-box;
  padding-top: 36px;
  padding-left: 42px;
}
.left-box-content {
  width: 129px;
}
.left-box-item {
  width: 129px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #3fffea;
  background-image: url("../../assets/image/oneMap/right-item.png");
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
.left-box-item-s {
  background-image: url("../../assets/image/oneMap/right-item-s.png");
}
</style>
