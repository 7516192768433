import request from '@/utils/request'

export function getSheBeiList(data) {
  return request({
    url: 'https://webapi.nbiotyun.com/api/v1/dev/get',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
      'appKey': 'PQH6fxUP',
      'timestamp': data.timestamp,
      'signature': data.signature
    }
  })
}

export default {
  getSheBeiList
}
