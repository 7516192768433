import Vue from 'vue'
import VueViewer, { directive as viewerDirective } from 'v-viewer'
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)
Vue.use(VueViewer)
VueViewer.setDefaults({
  Options: {
    inline: true, // 是否启用inline模式
    button: true, // 是否显示右上角关闭按钮
    // navbar: true, // 是否显示缩略图底部导航栏
    title: true, // 是否显示当前图片标题，默认显示alt属性内容和尺寸
    // toolbar: true, // 是否显示工具栏
    tooltip: true, // 放大或缩小图片时，是否显示缩放百分比，默认true
    fullscreen: true, // 播放时是否全屏，默认true
    loading: true, // 加载图片时是否显示loading图标，默认true
    loop: true, // 是否可以循环查看图片，默认true
    movable: true, // 是否可以拖得图片，默认true
    zoomable: true, // 是否可以缩放图片，默认true
    rotatable: true, // 是否可以旋转图片，默认true
    scalable: true, // 是否可以翻转图片，默认true
    toggleOnDblclick: true, // 放大或缩小图片时，是否可以双击还原，默认true
    transition: true, // 使用 CSS3 过度，默认true
    // keyboard: true, // 是否支持键盘，默认true
    zoomRatio: 0.1, // 鼠标滚动时的缩放比例，默认0.1
    minZoomRatio: 0.01, // 最小缩放比例，默认0.01
    maxZoomRatio: 100, // 最大缩放比例，默认100
    url: 'data-source' // 设置大图片的 url
  },

  defaultOptions: {
    /**
       *  数字含义:
       *  0 or false: hide the navbar
          1 or true: show the navbar
          2: show the navbar only when the screen width is greater than 768 pixels
          3: show the navbar only when the screen width is greater than 992 pixels
          4: show the navbar only when the screen width is greater than 1200 pixels
       */
    toolbar: {
      zoomIn: 0,
      zoomOut: 0,
      oneToOne: 0,
      reset: 0,
      prev: {
        show: 4,
        size: 'large'
      },
      play: 0,
      next: {
        show: 4,
        size: 'large'
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0
    },
    zoomRatio: 0.3 // 缩放比率 默认是0.1
  }
}) // 图片预览器
// 用于图片预览的指令方式调用 在元素上加上会处理元素下所有的图片,为图片添加点击事件,点击即可预览
Vue.directive('viewer', viewerDirective({
  debug: true
}))
